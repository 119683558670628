import React from "react"
import styled from "styled-components"

import { Container } from "../global"
import Logo from "../../components/common/Logo";

const FooterWrapper = styled.footer`
  padding: 30px;
  background-color: white;
  border-top: 1px solid #bababa;
  margin-top: 50px;
  text-align: center;
`;

const LogoWrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Copyright = styled.div`
    padding-top: 20px;
    font-size: 15px;
    opacity: 0.7;
`;

const Footer = () => (
  <FooterWrapper id="footer">
    <Container>
      <LogoWrapper><Logo/></LogoWrapper>
      <Copyright>© Copyright 2021 Obersis. All rights reserved.</Copyright>
    </Container>
  </FooterWrapper>
)

export default Footer
