import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const LogoWrapper = styled.div`
  width: 130px;
  display: block;
`;


const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obersis-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
      <LogoWrapper>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </LogoWrapper>
    )
}

export default Logo
