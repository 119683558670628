import React from 'react'
import styled from 'styled-components'
import { Container } from "../global"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'
import BackgroundVideo from '../common/VideoBackground'

const LandingHeaderWrapper = styled.div`
    margin-top: 100px;
`;

const LandingHeaderTitle = styled.h1`
    text-align: right;
    font-family: ${props => props.theme.font.bold};
`;

const LandingHeader = (props) => {
    const response = useStaticQuery(getImage);
    console.log(response);
    return (
        <Container>
            <LandingHeaderWrapper
            data-sal="zoom-in"
            data-sal-delay="300"
            data-sal-easing="ease">
                <LandingHeaderTitle>Building a stable innovation, technology and entrepreneurship ecosystem.</LandingHeaderTitle>
                <BackgroundVideo />
            </LandingHeaderWrapper>
        </Container>
    )
}

const getImage = graphql`
    query {
        file(relativePath: { eq: "bg-header.png" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
        }
        }
    }
`;

export default LandingHeader
