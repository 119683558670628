import React from "react"
import Video from "../../videos/video-bg.mp4"
import styled from 'styled-components'

const VideoWrapper = styled.div`
    width: 100%;
    overflow: hidden;

    video {
        object-fit: cover;
        width: 100%;
    }
`;

const VideoBackground = () => (
    <VideoWrapper>
        <video autoplay="true" loop="true" muted playsInline playsinline>
            <source src={Video} type="video/mp4" />
        </video>
  </VideoWrapper>
);

export default VideoBackground