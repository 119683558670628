import React from 'react'
import styled from 'styled-components'
import { Container } from "../../global"
import {useStaticQuery, graphql} from 'gatsby'
import Service from './Service'

const OurServicesTitle = styled.h3`
    text-align: center;
    padding: 30px 0;
`;

const ServicesWrapper = styled.div`
  display: flex;

  @media (max-width: ${props => props.theme.screen.sm}) {
        display: block;
    }
`;

const OurServices = () => {
    const response = useStaticQuery(getImages);    
    return (
      <div id="services">
          <Container>
              <OurServicesTitle
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease">Our Services</OurServicesTitle>
              <ServicesWrapper>
                <Service 
                    imagePos='right' {...response.allFile.edges[0]} 
                    title="Startup Acceleration"
                    body="We help early stage startups succeed providing them with mentorship, advice, resources, connections, etc... We have successfully helped several startups across Spain.
                    "/>
                <Service 
                    imagePos='left' {...response.allFile.edges[1]} 
                    title="Corporate Venturing"
                    body="We advise large corporates and industrial organisations either to develop, sponsor or invest in startups in order to develop innovative products or services to stay competitive.
                    "/>
                <Service 
                    imagePos='right' {...response.allFile.edges[2]} 
                    title="Startup Investment"
                    body="We know the technology sector is an inescapably huge investment opportunity and we want to make tech startups thrive. Our 10+ investments proves our knowledge and expertise.
                    "/>
                </ServicesWrapper>
          </Container>
        </div>
    )
}

const getImages = graphql`
{
  allFile(filter: {relativeDirectory: {eq: "services"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
    
`;

export default OurServices
