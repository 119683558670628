import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"
import LandingHeader from '../components/sections/LandingHeader'
import OurServices from '../components/sections/OurServices/OurServices'
import Companies from '../components/sections/Companies'
import Contact from '../components/sections/Contact'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <LandingHeader />
    <OurServices />
    <Companies />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
