import React from 'react'
import styled from 'styled-components'
import { Container } from "../global"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'

const CompaniesTitle = styled.h3`
    text-align: center;
    padding: 30px 0;
`;

const CompaniesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

`;

const CompanyImageWrapper = styled.div`
    width: 130px;
    margin: 40px;
`;

const companiesLinks = [
    "https://glovoapp.com/en/",
    "https://tulotero.es",
    "https://www.genial.ly/en",
    "https://holaunibo.com",
    "https://proptex.com",
    "https://caliope.app",
    "https://www.aulart.com/es",
    "https://www.tivify.es/es/",
    "https://rimbo.rent",
    "https://www.biapower.io",
    "https://www.vermutapp.com",
    "https://www.numberly.io",
    "https://clarklens.com",
    "https://rviewer.io",
    "https://www.planetdataset.com",
    "https://unitedgamers.pro/es/",
    "https://www.emerita.legal",
    "https://dribo.es",
    "https://www.brainity.co/en",
    "https://www.last.app",
    "https://icofunding.com",
]

const Companies = () => {
    const response = useStaticQuery(getImages);
    return (
        <div id="companies">
            <Container>
                    <CompaniesTitle>Companies we have invested in</CompaniesTitle>
                    <CompaniesWrapper>
                        {response.allFile.edges.map((el, ind, arr) => {
                            return(
                                <a href={companiesLinks[ind]} target="_blank">
                                <CompanyImageWrapper>
                                    <Img fluid={el.node.childImageSharp.fluid} />
                                </CompanyImageWrapper>
                                </a>
                            )
                        })}
                    </CompaniesWrapper>
            </Container>
        </div>
    )
}

const getImages = graphql`
{
    allFile(filter: {relativeDirectory: {eq: "companies"}}, sort: {order: ASC, fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  
`;



export default Companies
