import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from 'gatsby'

const ServiceWrapper = styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    width: 33%;
    padding: 10px;

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 100%;

        h4 {
            margin-top: 10px;
        }
    }
`;

const ServiceText = styled.div`
    flex: 50%;
    padding: 30px;
`;

const ServiceImageWrapper = styled.div`
    overflow: hidden;
    height: 300px;
    width: 100%;
    
    div {
        height: 100%;
        width: 100%;
    }
`;

const Service = (props) => {
    console.log(props);
        return (
            <ServiceWrapper
            data-sal="zoom-in"
            data-sal-delay="500"
            data-sal-easing="ease">
                <ServiceImageWrapper>
                    <Img fluid={props.node.childImageSharp.fluid} />
                </ServiceImageWrapper>
                <ServiceText>
                    <h4>{props.title}</h4>
                    <p>{props.body}</p>
                </ServiceText>
            </ServiceWrapper>
        )
}

export default Service
