import React from 'react'
import styled from 'styled-components'
import { Container } from "../global"
import Img from 'gatsby-image'

const ContactTitle = styled.h3`
    text-align: center;
    padding: 30px 0;
`;

const FORM = styled.form`
    max-width: 900px;
    margin: auto;
`;

const FormGroup = styled.div`
    margin: 30px 0;

    label {
        padding-bottom: 20px;
        display: block;
    }

    input, textarea {
        width: 100%;
        height: 40px;
        padding: 10px;
        resize: none;
        border: 1px solid #bababa;
    }

    textarea {
        height: 120px;
    }
`;

const Submit = styled.button`
    background-color: black;
    padding: 16px 40px;
    color: white;
    display: block;
    width: 30%;
    margin: auto;
    transition: all ease 0.5s;
    
    &:hover {
        box-shadow: 1px 1px 10px #bababa;
    }
`;

const Contact = () => {
    return (
        <Container>
            <div id="contact">
                <ContactTitle>Contact Us</ContactTitle>
                <FORM action="https://formspree.io/jsm@obersis.com" method="POST">
                    <FormGroup>
                        <label>Name</label>
                        <input type="text" name="name" id="name" placeholder="Paul McCoy"/>
                    </FormGroup>
                    <FormGroup>
                        <label>Email</label>
                        <input type="email" name="email" id="email" placeholder="paulmccoy@gmail.com"/>
                    </FormGroup>
                    <FormGroup>
                        <label>Message</label>
                        <textarea type="test" name="message" id="message" placeholder="Enter your message here"/>
                    </FormGroup>
                    <Submit type="submit">Send</Submit>
                </FORM>
            </div>
        </Container>
    )
}

export default Contact
